:root {
  --primary: rgb(102, 119, 250)
}

.App {
  text-align: center;
  background-color: #0f0f0f;
  width: 100%;
  height: 100%;
}

.name {
  color: var(--primary);
}

.title {
  margin-top: 60px;
}

.projects-title {
  margin-top: 50px;
}

.projects {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}

.proj {
  width: 200px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid var(--primary);
  border-radius: 10px;
  background-color: #222222;
  cursor: pointer;
  transition: .3s;
}
.proj:focus-visible {
  outline: none;
  outline: 2px solid var(--primary);
  outline-offset: 2px;
}

.proj:hover {
  transform: scale(1.05);
  background-color: transparent;
}

.proj-title {
  font-weight: bold;
  font-size: 20px;
}

.sci {
  margin-top: 50px;
  padding: 6px 25px 6px 25px;
  display: inline-block;
  background-color: #303030d8;
  border-radius: 5px;
  font-family: monospace;
}

.sci input {
  background: none;
  border: none;
  outline: none;
  width: auto; /* Permet à l'input de s'élargir automatiquement */
  min-width: 50px; /* Définissez la largeur minimale souhaitée */
}